import { Controller } from 'stimulus'

export default class WNWController extends Controller {
  static targets = ['bullet', 'item', 'list']

  connect() {
    this.setActiveBullet()
  }

  // Sets the active bullet based on the currently visible item
  setActiveBullet() {
    const items = this.itemTargets
    const bullets = this.listTarget.children

    items.forEach((item, index) => {
      if (!item.classList.contains('is-hidden')) {
        bullets[index].classList.add('is-active')
      } else {
        bullets[index].classList.remove('is-active')
      }
    })
  }

  // Called when a bullet is clicked
  showItem(event) {
    const index = this.bulletTargets.indexOf(event.currentTarget)
    const items = this.itemTargets

    items.forEach((item, i) => {
      if (i === index) {
        item.classList.remove('is-hidden')
      } else {
        item.classList.add('is-hidden')
      }
    })

    // Pause all video players on the page
    const videos = document.querySelectorAll('mave-player')
    videos.forEach((video) => {
      video.pause()
    })

    // if the item target has data-autoplay true, then play the mave-player inside it
    const mavePlayer = items[index].querySelector('mave-player')

    if (mavePlayer && items[index].getAttribute('data-autoplay') === 'true') {
      mavePlayer.play()
    }

    this.setActiveBullet()
  }
}
