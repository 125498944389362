/* eslint-disable no-undef */
import { Controller } from 'stimulus'
import { BREAKPOINTS } from '../../../_js/base/consts'
import mediaFrom from '../../../_js/utils/media-from'

// THREE is added separately to reduce the size of bundled js source
// Check webpack.config.js and switch-to-sketch.liquid for the details

export default class Hero extends Controller {
  static targets = ['animationWrapper']

  static getScrollTop = function () {
    return window.scrollY || (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
  }

  connect() {
    this.initAnimation()
    this.animate()
  }

  initAnimation() {
    const w = this.animationWrapperTarget.offsetWidth
    const h = this.animationWrapperTarget.offsetHeight
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.setSize(w, h)
    this.renderer.setPixelRatio(window.devicePixelRatio)

    this.animationWrapperTarget.appendChild(this.renderer.domElement)

    this.scene = new THREE.Scene()

    // Init camera
    this.setCameraViewport(w, h)

    // Init lights
    const light = new THREE.AmbientLight(0x404040)
    this.scene.add(light)

    const spotLight1 = new THREE.SpotLight(0xffffff)
    spotLight1.position.set(2, 5, -1)
    spotLight1.decay = 1.5
    spotLight1.intensity = 1.5
    spotLight1.distance = 7
    this.scene.add(spotLight1)

    const spotLight2 = new THREE.SpotLight(0xffffff)
    spotLight2.position.set(0, -5, -4)
    spotLight2.decay = 1.6
    spotLight2.intensity = 2.8
    spotLight2.distance = 10
    this.scene.add(spotLight2)

    // Init materials
    const material = new THREE.MeshPhongMaterial({
      color: 0xfff066,
      emissive: 0x661100,
      emissiveIntensity: 1.5,
      shininess: 60,
      specular: 0xffff55,
    })

    const loader = new THREE.GLTFLoader()

    loader.load('/switch-to-sketch/hero/5-sided-diamond.gltf', (gltf) => {
      const geometry = gltf.scene.children[0].geometry

      // Add stem wireframe to the background
      const edges = new THREE.EdgesGeometry(geometry)
      this.line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0x000000, transparent: true, opacity: 0.16, linewidth: 2 })
      )
      this.line.scale.set(11, 11, 11)
      this.line.rotation.x = 3.14
      this.line3D = new THREE.Object3D().add(this.line)
      this.line3D.position.x = -7
      this.line3D.position.y = -7
      this.scene.add(this.line3D)

      this.mesh = new THREE.Mesh(geometry, material)
      this.mesh.position.y = 1.2
      this.mesh.rotation.y = 0.5
      this.mesh.rotation.x = 3.14
      this.mesh.scale.set(1.7, 1.7, 1.7)
      this.mesh3D = new THREE.Object3D().add(this.mesh)
      this.scene.add(this.mesh3D)

      spotLight1.target = this.mesh3D
      spotLight2.target = this.mesh3D
      this.camera.lookAt(this.mesh3D.position)

      this.element.classList.add('is-animation-loaded')
    })
  }

  resizeRenderer() {
    const w = this.animationWrapperTarget.offsetWidth
    const h = this.animationWrapperTarget.offsetHeight
    this.setCameraViewport(w, h)
    this.renderer.setSize(w, h)
  }

  setCameraViewport(w, h) {
    const viewSize = 10
    const aspectRatio = w / h

    const viewport = {
      viewSize: viewSize,
      aspectRatio: aspectRatio,
      left: (-aspectRatio * viewSize) / 2,
      right: (aspectRatio * viewSize) / 2,
      top: viewSize / 2,
      bottom: -viewSize / 2,
      near: -100,
      far: 100,
    }

    if (!this.camera) {
      this.camera = new THREE.OrthographicCamera(
        viewport.left,
        viewport.right,
        viewport.top,
        viewport.bottom,
        viewport.near,
        viewport.far
      )

      this.camera.position.set(3, 2, 0)
    } else {
      this.camera.left = viewport.left
      this.camera.right = viewport.right
      this.camera.top = viewport.top
      this.camera.bottom = viewport.bottom
      this.camera.updateProjectionMatrix()
    }
  }

  rotateMesh() {
    const scrollTop = Hero.getScrollTop()

    if (this.mesh3D) {
      this.mesh3D.children[0].rotation.y += 0.005
      if (scrollTop - this.scrollTop > 0 && scrollTop > 0) {
        this.mesh3D.rotation.y += -(scrollTop - this.scrollTop) / 200
      }

      // scale mesh on smaller displays
      let scale = 1.7
      let posY = 1.3
      if (!mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
        scale = 1.15
        posY = 1.75
      }
      this.mesh.scale.set(scale, scale, scale)
      this.mesh.position.y = posY
    }

    if (this.line3D) {
      this.line3D.children[0].rotation.y += 0.001
    }

    this.scrollTop = scrollTop
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this))
    this.rotateMesh()
    this.renderer.render(this.scene, this.camera)
  }
}
