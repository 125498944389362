import { Controller } from 'stimulus'

export default class InvisionController extends Controller {
  static targets = ['compareTable']

  connect() {}

  seeMoreFeatures() {
    this.compareTableTarget.dataset.hidingFeatures = 'false'
  }
}
