import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'

export default class AboutUsController extends Controller {
  static targets = ['question', 'questions', 'indicator', 'nav', 'navShow', 'navHide']

  connect() {
    this.onPageScroll = throttle(this.onPageScroll.bind(this), 30)
    window.dispatchEvent(new window.Event('scroll'))

    // Calculate Sketch Age
    AboutUsController.sketchAge()
  }

  onPageScroll() {
    this.questionTargets.forEach((question, questionIndex) => {
      const questionRect = question.getBoundingClientRect()
      const questionInViewport = questionRect.y < window.innerHeight / 2 && questionRect.y + questionRect.height > 0
      if (questionInViewport) {
        this.indicatorTargets.forEach((indicator, indicatorIndex) => {
          indicator.classList.toggle('js-is-selected', indicatorIndex === questionIndex)
        })
      }
      question.classList.toggle('is-intersecting', questionInViewport)

      this.questionsTarget.classList.toggle(
        'is-intersecting',
        this.questionTargets.filter((q) => q.classList.contains('is-intersecting')).length > 0
      )
    })

    // Toggle the sticky navigation's visibility based on the position of the navShow and navHide target elements in the viewport
    const navShowRectY = this.navShowTarget.getBoundingClientRect().y
    const navShowMarginTop = parseFloat(window.getComputedStyle(this.navShowTarget).getPropertyValue('margin-top'))
    const navHideRectY = this.navHideTarget.getBoundingClientRect().y
    const navHideMarginTop = parseFloat(window.getComputedStyle(this.navHideTarget).getPropertyValue('margin-top'))
    const navHeight = this.navTarget.getBoundingClientRect().height

    this.navTarget.classList.toggle(
      'is-visible',
      navShowRectY - navHeight - navShowMarginTop <= 0 && navHideRectY - navHeight - navHideMarginTop > 0
    )
  }

  static sketchAge() {
    const paragraphs = document.querySelectorAll('.our-company__article__paragraph')
    const paragraphWithAge = Array.from(paragraphs).find((element) => element.innerText.includes('12 years'))
    paragraphWithAge.innerHTML = paragraphWithAge.innerHTML.replace('12', AboutUsController.calculageAge())

    const logosYear = document.querySelectorAll('.about-sketch__bullet__image-year')
    const lastLogo = Array.from(logosYear).find((element) => element.innerText.includes('2022'))
    lastLogo.innerHTML = lastLogo.innerHTML.replace('2022', AboutUsController.currentYear())
  }

  static calculageAge() {
    var sketchBirthday = new Date('2010-09-07')
    var ageDifMs = Date.now() - sketchBirthday.getTime()
    var ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  static currentYear() {
    return new Date().getFullYear()
  }
}
