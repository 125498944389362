import FormController from '../../_components/form/form_controller'

export default class NewsletterController extends FormController {
  static targets = [
    'email', // email field
    'button', // submit button
    'error', // error message element
    'gdprConfirm',
  ]

  static HS_SUBSCRIPTION_ID = 9320643

  connect() {
    this.element.submit = this.submitRemotely.bind(this)
  }

  enable() {
    this.buttonTarget.disabled = !this.element.gdpr.checked
  }

  submitRemotely() {
    // Capture form data before any inputs are possibly disabled
    const formData = new FormData(this.element)

    // the GDPR checkbox needs to be checked
    if (!this.element.elements.gdpr.checked) return

    // Disable submission
    this.disableSubmission()

    /**
     * Prepate the data for the newsletter registration
     * based on this guideline provided by HubSpot:
     * https://legacydocs.hubspot.com/docs/methods/forms/submit_form
     */
    const postData = { fields: [] }

    // Add postdata timestamp
    postData.submittedAt = new Date().getTime()

    // Add newsletter form key / value pairs
    formData.forEach((value, key) => {
      if (key != 'gdpr') postData.fields.push({ name: key, value: value, objectTypeId: '0-1' })
    })

    // Cookie consent related additional params
    // This is mandatory as GDPR options are enabled in HubSpot's main settings
    postData.legalConsentOptions = {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Sketch to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: NewsletterController.HS_SUBSCRIPTION_ID,
            text: 'I agree to receive marketing emails from Sketch.',
          },
        ],
      },
    }

    // send postData to HubSpot
    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else return this.remoteSubmissionSuccessful()
      })
      .catch(() => {
        this.remoteSubmissionFailed()
      })
      .finally(() => this.resetForm())
  }

  resetForm() {
    this.buttonTarget.classList.remove(FormController.classNames.WAITING)
  }
}
