import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import { Controller } from 'stimulus'

import Overlay from '../overlay/overlay'
import FocusTrap from '../../_js/utils/focus-trap'
import { KEYS } from '../../_js/base/consts'
import * as userUtils from '../../_js/helpers/user'
import userProfileView from '../user-profile/user-profile'

/**
 *
 *
 * @export
 * @class MainNav
 * @extends {Controller}
 */
export default class MainNav extends Controller {
  static targets = ['navToggle', 'authProfile', 'authActions', 'mainNav', 'mobileNav', 'downloadWrapper']

  connect() {
    this.overlay = new Overlay({ classes: 'overlay--navigation' })
    this.focusTrap = FocusTrap(this.element, { grabFocusOnActivate: false })

    // Clicking overlay resets state
    this.overlay.on('dismiss', () => {
      this.active = false
    })

    this.state = {
      isActive: false,
      layout: this.queryLayoutMode(),
      user: undefined,
    }

    this.checkLoginState()

    if (MainNav.isSafari()) {
      this.element.classList.add('is-safari')
    }

    this.checkIfDisplayDownloadButton()
  }

  /**
   *
   * @param {StorageEvent?} event
   */
  checkLoginState(event) {
    // if a storage event but not on the relevant keys, early return
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null
  }

  checkIfDisplayDownloadButton() {
    if (navigator.userAgent.search('Mac') !== -1) {
      this.downloadWrapperTarget.classList.add('operation-system-is-mac')
    }
  }

  /**
   * Renders authentication as part of the navigation
   */
  renderAuth() {
    if (this.user) {
      this.authProfileTargets.forEach((/** @type Element */ target) => {
        Array.from(target.children).forEach((child) => child.remove())
        target.appendChild(userProfileView(this.user))
      })
    }

    this.authProfileTargets.forEach((target) => target.classList.toggle('is-hidden', !this.user))
    this.authActionsTargets.forEach((target) => target.classList.toggle('is-hidden', this.user))
  }

  set user(user) {
    if (!isEqual(user, this.user)) {
      this.state.user = user
      this.renderAuth()
    }
  }

  get user() {
    return this.state.user
  }

  get active() {
    return this.state.isActive
  }

  get layout() {
    return this.state.layout
  }

  /**
   * Set the active state to a new value
   * @param {boolean} value
   */
  set active(value) {
    if (this.state.isActive == value) return
    this.state.isActive = value

    if (this.active) {
      this.navToggleTarget.setAttribute('aria-expanded', 'true')
      this.element.classList.remove('was-active')
      this.element.classList.add('is-active')
      // Allow mobile scrolling inside the nav
      this.mobileNavTarget.classList.add('body-scroll-lock-ignore')
      this.mobileNavTarget.scrollTop = 0

      // Hide ribbon large
      document.querySelector('.ribbon-large')?.classList.add('hide')
    } else {
      this.navToggleTarget.setAttribute('aria-expanded', 'false')
      this.element.classList.remove('is-active')
      this.element.classList.add('was-active')
      // Allow scrolling inside the nav
      this.mobileNavTarget.classList.remove('body-scroll-lock-ignore')

      // Show ribbon large
      document.querySelector('.ribbon-large')?.classList.remove('hide')
    }

    this.updateModality()
  }

  /**
   * Set the layout mode to a new value
   * @param {{('wide'|'narrow')}} value - the new layout mode
   */
  set layout(value) {
    if (value === this.state.layout) return
    this.state.layout = value

    // Changing to desktop resets navigation
    if (this.layout == 'wide') {
      this.active = false
    }
    this.updateModality()
  }

  /**
   * Detect layout mode (wide or expandable) by checking whether the main navigation toggle
   * button is visible, since it will only appear when the navigation is expandable.
   * @returns {('wide'|'narrow')}
   */
  queryLayoutMode() {
    const toggleIsVisible = !!(
      this.navToggleTarget.offsetWidth ||
      this.navToggleTarget.offsetHeight ||
      this.navToggleTarget.getClientRects().length
    )
    return toggleIsVisible ? 'narrow' : 'wide'
  }

  /**
   * Enable or disable modality of navigation (overlay and focus trap), depending on state
   */
  updateModality() {
    if (this.active && this.layout == 'narrow') {
      this.overlay.show()
      this.focusTrap.activate()
    } else {
      this.overlay.hide()
      this.focusTrap.deactivate()
    }
  }

  // Actions ---------------------------------------------------------------------------------------
  //

  /**
   * Navigation was expanded or collapsed
   */
  toggleNav() {
    this.active = !this.active
  }

  mobileToggleNav() {
    if (this.active && this.layout == 'narrow') {
      this.toggleNav()
    }
  }

  /**
   * Navigation was resized
   * @param {UIEvent} event
   */
  resized = throttle(
    () => {
      this.layout = this.queryLayoutMode()
    },
    200,
    { trailing: true }
  )

  /**
   * A key was pressed
   * @param {KeyboardEvent} event
   */
  keyPressed(event) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode === KEYS.escape) {
      if (this.active) {
        this.active = null
        event.preventDefault()
      }
    }
  }

  scrolled() {
    if (window.scrollY >= 120) {
      if (this.mainNavTarget.classList.contains('was-sticky')) {
        this.mainNavTarget.classList.remove('was-sticky')
      }

      this.mainNavTarget.classList.add('is-sticky')
    } else {
      if (this.mainNavTarget.classList.contains('is-sticky')) {
        this.mainNavTarget.classList.add('was-sticky')
      }

      this.mainNavTarget.classList.remove('is-sticky')
    }
  }

  addBackground(event) {
    event.target
      .closest('.main-nav__subnav__mega-menu')
      ?.classList.add('main-nav__subnav__mega-menu--' + event.target.dataset.background)
  }

  removeBackground(event) {
    event.target
      .closest('.main-nav__subnav__mega-menu')
      ?.classList.remove('main-nav__subnav__mega-menu--' + event.target.dataset.background)
  }

  mobileNavScrolled() {
    this.mobileNavTarget.style.backgroundPosition = `0px ${Math.min(0, -this.mobileNavTarget.scrollTop / 3)}px`
  }

  static isSafari() {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') == -1) {
        return true
      }
    }
    return false
  }

  toggleGridLayout(e) {
    if (this.active) {
      e.preventDefault()
      const switchInput = e.target.querySelector('input')
      switchInput.checked = !switchInput.checked
      window.dispatchEvent(new CustomEvent('grid-layout-toggle'))
    }
  }

  openHomepageSwitcher(e) {
    if (this.active) {
      e.preventDefault()
      this.focusTrap.deactivate()
      window.dispatchEvent(new CustomEvent('homepage-switcher-open'))
    }
  }

  focusTrapActive() {
    this.focusTrap.activate()
  }
}
