import { Controller } from 'stimulus'
import { KEYS, BREAKPOINTS } from '../../_js/base/consts'
import mediaFrom from '../../_js/utils/media-from'
import Overlay from '../overlay/overlay'
import FocusTrap from '../../_js/utils/focus-trap'

let konamiIndex = 0

export default class HomepageSwitcher extends Controller {
  static targets = ['switcherLinks', 'keys']

  connect() {
    this.overlay = new Overlay({ classes: 'overlay--homepage-switcher' })
    this.focusTrap = FocusTrap(this.element, { grabFocusOnActivate: false })

    this.hideWindowsOrMacShortcuts()
    this.setActiveSwitcherLink()
  }

  eventKeyUp(e) {
    this.removeKeyPressedClass(e)
  }

  eventKeyDown(e) {
    this.toggleVisibility(e)
    this.animateKeyPress(e)
  }

  openSwitcher() {
    this.element.classList.add('is-visible')

    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      document.body.style.overflow = 'hidden'
    } else {
      this.focusTrap.activate()
    }
  }

  closeSwitcher() {
    this.element.classList.remove('is-visible')

    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      document.body.style.overflow = 'initial'
    } else {
      this.focusTrap.deactivate()
      window.dispatchEvent(new CustomEvent('mobile-nav-focus'))
    }
  }

  setActiveMenuSelectionIndex() {
    this.menuSelectionIndex = this.switcherLinksTargets.findIndex((switcherLink) =>
      switcherLink.parent().classList.contains('is-active')
    )
  }

  hideWindowsOrMacShortcuts() {
    const isMac = navigator.userAgent.indexOf('Mac') != -1

    this.keysTargets.forEach((key) => {
      if (isMac && key.dataset.operatingSystem === 'windows') {
        key.remove()
      } else if (!isMac && key.dataset.operatingSystem === 'mac') {
        key.remove()
      }
    })

    const firstAnnotationWithSpecialKeyStroke = document.querySelector('.annotation__text')
    if (firstAnnotationWithSpecialKeyStroke) {
      const allKbd = firstAnnotationWithSpecialKeyStroke.querySelectorAll('kbd')

      allKbd.forEach((key) => {
        if (isMac && key.dataset.operatingSystem === 'windows') {
          key.remove()
        } else if (!isMac && key.dataset.operatingSystem === 'mac') {
          key.remove()
        }
      })
    }
  }

  setActiveSwitcherLink() {
    const currentUrl = window.location.href

    this.switcherLinksTargets.forEach((switcherLink) => {
      // special case first, one time our website doesn't add a trailing slash for the /home
      if (switcherLink.href.includes('/home/') && currentUrl.includes('/home')) {
        switcherLink.parent().classList.add('is-active')
      } else if (currentUrl === switcherLink.href && !switcherLink.parent().classList.contains('hidden')) {
        switcherLink.parent().classList.add('is-active')
      }
    })
  }

  toggleVisibility(e) {
    const isMac = navigator.userAgent.indexOf('Mac') != -1
    if (
      ((isMac && e.ctrlKey && e.keyCode === KEYS.H) || (!isMac && e.shiftKey && e.ctrlKey && e.keyCode === KEYS.H)) &&
      ['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) === -1
    ) {
      this.toggleOverlay()
    }

    const konamiCode = [
      KEYS.arrowUp,
      KEYS.arrowUp,
      KEYS.arrowDown,
      KEYS.arrowDown,
      KEYS.arrowLeft,
      KEYS.arrowRight,
      KEYS.arrowLeft,
      KEYS.arrowRight,
      KEYS.B,
      KEYS.A,
    ]

    if (e.keyCode === konamiCode[konamiIndex]) {
      konamiIndex++
      if (konamiIndex === konamiCode.length) {
        this.toggleOverlay()
        konamiIndex = 0
      }
    } else {
      konamiIndex = 0
    }
  }

  toggleOverlay() {
    if (this.element.classList.contains('is-visible')) {
      this.closeSwitcher()
    } else {
      this.openSwitcher()
    }
  }

  animateKeyPress(e) {
    this.keysTargets.forEach((key) => {
      if (key.dataset.keyMap === 'control' && e.ctrlKey) {
        key.classList.add('is-pressed')
      } else if (key.dataset.keyMap === 'shift' && e.shiftKey) {
        key.classList.add('is-pressed')
      } else if (parseInt(key.dataset.keyMap) === e.keyCode) {
        key.classList.add('is-pressed')
      }
    })
  }

  removeKeyPressedClass(e) {
    this.keysTargets.forEach((key) => {
      if (key.dataset.keyMap === 'control' && !e.ctrlKey) {
        key.classList.remove('is-pressed')
      } else if (key.dataset.keyMap === 'shift' && !e.shiftKey) {
        key.classList.remove('is-pressed')
      } else if (parseInt(key.dataset.keyMap) === e.keyCode) {
        key.classList.remove('is-pressed')
      }
    })
  }
}
