import { Controller } from 'stimulus'

export default class WelcomeBackController extends Controller {
  static targets = ['name']

  connect() {
    this.replaceNameInGreeting()
  }

  static getNameFromUrlQuery() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const { recipient } = Object.fromEntries(urlParams.entries())
      return recipient || 'there'
    } catch (error) {
      return 'there'
    }
  }

  replaceNameInGreeting() {
    const name = this.constructor.getNameFromUrlQuery()
    this.nameTarget.innerHTML = name
  }
}
