/* global algoliasearch, autocomplete */
import { Controller } from 'stimulus'
import h from 'hyperscript'
import slugify from 'slugify'
import throttle from 'lodash/throttle'
import Overlay from '../../_components/overlay/overlay'
import Algolia from '../algolia'
import { BREAKPOINTS } from '../base/consts'

/**
 * Search Controller
 *
 * Values:
 * - index-name              - Index name to pass Agolia
 * - filters                 - Filters to pass to Algolia
 * - show-recommendations    - Whether to show recommendations ("true"|"false")
 * - recommendations-heading - Heading for the recommendations list
 */
export default class Search extends Controller {
  static targets = ['input']

  static values = {
    indexName: String,
    filters: String,
    showRecommendations: Boolean,
    showInlineRecommendations: Boolean,
    recommendationsHeading: String,
    limitResults: Number,
    showHeadings: Boolean,
    dropdownHeading: String,
    dropdownPosition: String,
    keepResultsOpen: Boolean,
    openLinksInNewTab: Boolean,
    priorityCategory: String,
  }

  static AUTOCOMPLETE_DEFAULTS = {
    hint: false,
    tabAutocomplete: false,
    openOnFocus: true,
    minLength: 0,
    debug: false, // Keep results open
  }

  static INLINE_RECOMMENDATIONS = ['Interface', 'Libraries', 'Symbols', 'Prototyping', 'Collaborating']

  // Create a new static which contains strings from INLINE_RECOMMENDATIONS but with a zero-space character appended
  static INLINE_RECOMMENDATIONS_WITH_ZWSP = Search.INLINE_RECOMMENDATIONS.map(
    (recommendation) => recommendation + '\u200B'
  )

  connect() {
    this.client = algoliasearch(Algolia.applicationID, Algolia.searchAPIKey)
    this.index = this.client.initIndex(this.indexNameValue)
    this.hitsSource = autocomplete.sources.hits(this.index, {
      hitsPerPage: this.limitResultsValue,
      filters: this.filtersValue,
    })
    this.overlay = new Overlay()
    this._initAutocomplete()
  }

  disconnect() {
    this.overlay.destroy()
  }

  _initAutocomplete() {
    const autocompleteOptions = Search.AUTOCOMPLETE_DEFAULTS

    if (this.keepResultsOpenValue) {
      autocompleteOptions.debug = this.keepResultsOpenValue
    }

    if (!this.showRecommendationsValue) {
      autocompleteOptions.minLength = 1
    }

    this.autocomplete = autocomplete(this.inputTarget, autocompleteOptions, {
      source: this._sortHits.bind(this),
      templates: Search.templates,
    })

    // Ability to hit enter to go to URL on selected
    this.autocomplete.on('autocomplete:selected', function (_event, suggestion) {
      window.location.href = suggestion.url
    })

    this.autocomplete.on('autocomplete:updated', () => {
      if (
        (this.showRecommendationsValue && this.inputTarget.value == '') ||
        Search.INLINE_RECOMMENDATIONS_WITH_ZWSP.includes(this.inputTarget.value)
      ) {
        this._injectRecommendationsHeading()
      } else if (this.showHeadingsValue) {
        this._injectCategoryHeadings()
      }
      this._toggleDropdownHeading(this.inputTarget.value.length)
      this.resize()
    })

    // add dropdown heading if set
    this._injectDropdownHeading()

    // set dropdown position
    this.element.querySelector('.aa-dropdown-menu').style.position = this.dropdownPositionValue
  }

  static templates = {
    suggestion: function (suggestion) {
      // highlight keywords in the description fields
      var highlightedContent = Search.highlightKeywords(
        suggestion.content || suggestion.description || '',
        suggestion._highlightResult
      )

      return h(
        'a',
        {
          href: suggestion.url + (suggestion.anchor ? '#' + suggestion.anchor : ''),
          'data-category':
            suggestion.categories && suggestion.categories.length > 0 ? suggestion.categories[0] : suggestion.type,
        },
        [
          h('strong.aa-suggestion__title', suggestion.title),
          h('p.aa-suggestion__description', { innerHTML: highlightedContent }),
        ]
      )
    },
    empty: function () {
      return h('div.algolia-search__no-results', [
        h('h4.aa-suggestion__title', 'No results found'),
        h('p', 'Make sure all words are spelled correctly or try with different keywords.'),
      ])
    },
  }

  static highlightKeywords(text, hres) {
    if (hres && hres.content && hres.content.matchedWords) {
      Object.keys(hres.content.matchedWords).forEach(function (el, key) {
        var word = hres.content.matchedWords[key]
        if (word.length > 3) {
          // This is a bit convoluted, but fixes an issue with the matchedWords that Algolia sends us: they're all lowercase,
          // so doing a basic regex replace will turn 'Sketch' into 'sketch', and we can't have that :)
          var regex = new RegExp(word, 'gi')
          var matches = text.match(regex)
          if (matches) {
            for (var i = 0; i < matches.length; i++) {
              var match = matches[i]
              text = text.replace(match, '<span class="algolia__result-highlight">' + match + '</span>')
            }
          }
        }
      })
    }
    return text
  }

  // Add a heading above search recommendations
  _injectRecommendationsHeading() {
    const dropdownNode = this.element.querySelector('.aa-dataset-1')

    if (!this.hasRecommendationsHeadingValue || this.recommendationsHeadingValue === '') return

    const heading = h('header.algolia-search__header', [
      h(
        `h6.algolia-search__heading.algolia-search__heading--${slugify(
          this.recommendationsHeadingValue.toLowerCase()
        )}`,
        this.recommendationsHeadingValue
      ),
    ])

    if (this.showInlineRecommendationsValue) {
      const inlineRecommendations = h(
        '.algolia-search__suggestions',
        Search.INLINE_RECOMMENDATIONS_WITH_ZWSP.map((recommendation) => {
          const isActive = this.inputTarget.value ? recommendation === this.inputTarget.value : false
          return h(`span.algolia-search__suggestion${isActive ? '.is-active' : ''}`, recommendation)
        })
      )

      // Add inline recommendations to the search dropdown
      heading.appendChild(inlineRecommendations)
    }

    dropdownNode.prepend(heading)

    // Add event listener to each link
    const suggestions = this.element.querySelectorAll('.algolia-search__suggestion')
    suggestions.forEach((suggestion, index) => {
      suggestion.addEventListener('click', () => {
        // Set input value to clicked suggestion's text content
        this.inputTarget.value = suggestion.textContent.trim()

        // Add is-active class to clicked suggestion and remove it from other suggestions
        suggestions.forEach((otherSuggestion) => {
          if (otherSuggestion === suggestion) {
            otherSuggestion.classList.add('is-active')
          } else {
            otherSuggestion.classList.remove('is-active')
          }
        })

        // Refresh search results
        this.autocomplete.autocomplete.setVal(this.inputTarget.value)
      })
    })
  }

  // Add a heading above search dropdown
  _injectDropdownHeading() {
    if (!this.hasDropdownHeadingValue || this.dropdownHeadingValue === '') return

    this.dropdownHeading = h(`h5.aa-dropdown-heading.is-hidden`, this.dropdownHeadingValue)
    const dropdownNode = this.element.querySelector('.aa-dropdown-menu')
    dropdownNode.parentNode.insertBefore(this.dropdownHeading, dropdownNode)
  }

  _toggleDropdownHeading(inputValueLength) {
    if (!this.hasDropdownHeadingValue || this.dropdownHeadingValue === '') return
    this.dropdownHeading.classList.toggle('is-hidden', inputValueLength === 0)
  }

  // Add headings between each category and sort them based on category value
  _injectCategoryHeadings() {
    // Add group headers
    const categoryDivs = {}

    // Check if there are results
    const suggestionListElements = this.element.querySelectorAll('.aa-suggestion')
    if (!suggestionListElements.length) return

    suggestionListElements.forEach(function (suggestion) {
      const suggestionCategory = suggestion.querySelector('a').dataset.category.toLowerCase()

      // Check if a div for this category exists, and create it if not
      if (!categoryDivs[suggestionCategory]) {
        const categoryDiv = document.createElement('div')
        categoryDiv.className = 'algolia-search__suggestions-category'

        const headingNode = h(
          `h6.algolia-search__heading.algolia-search__heading--${slugify(suggestionCategory)}`,
          suggestionCategory.replace(/-/g, ' ')
        )
        categoryDiv.appendChild(headingNode)

        categoryDivs[suggestionCategory] = categoryDiv
      }

      // Append the suggestion to the appropriate category div
      categoryDivs[suggestionCategory].appendChild(suggestion)
    })

    const priorityCategory = this.priorityCategoryValue

    const parentElement = this.element.querySelector('.aa-suggestions')
    parentElement.innerHTML = ''

    if (priorityCategory) {
      // Sort the categories
      const sortedCategories = Object.keys(categoryDivs).sort((a, b) => {
        if (a === priorityCategory) return -1
        if (b === priorityCategory) return 1
        return a.localeCompare(b) // Alphabetical sorting for the rest
      })

      sortedCategories.forEach(function (category) {
        parentElement.appendChild(categoryDivs[category])
      })
    } else {
      Object.keys(categoryDivs).forEach(function (category) {
        parentElement.appendChild(categoryDivs[category])
      })
    }
  }

  /**
   * Sort suggestions by category
   * @param {String} query
   * @param {Function} callback
   */
  _sortHits(query, callback) {
    this.hitsSource(query, function (suggestions) {
      const suggestionsSortedByCategory = suggestions.sort((a, b) => {
        const catA = a.categories ? a.categories[0] : a.type
        const catB = b.categories ? b.categories[0] : b.type
        if (catA > catB) return 1
        if (catA < catB) return -1
        return 0
      })
      callback(suggestionsSortedByCategory)
    })
  }

  showOverlay() {
    this.overlay.show()
    this.element.classList.add('is-above-overlay')
    this.resize()
  }

  hideOverlay() {
    this.overlay.hide()
    this.element.classList.remove('is-above-overlay')
  }

  /**
   * Blur the input field if ESC key was hit and the input field is empty
   *

   * @param {KeyboardEvent} event
   */
  keyPressed(event) {
    if (event.key == 'Escape' && this.inputTarget.value == '') {
      this.inputTarget.blur()
    }
  }

  /**
   * Resize dropdown results window so it always fits in the browser window
   */
  resize = throttle(() => {
    if (this.dropdownPositionValue === 'absolute') {
      const dropdownMenuNode = this.element.querySelector('.aa-dropdown-menu')
      const menuConstraints = dropdownMenuNode.getBoundingClientRect()
      const elementsSpacing = window.innerWidth < BREAKPOINTS.VIEWPORT_L ? 16 : 64

      const height = window.visualViewport ? window.visualViewport.height : window.innerHeight

      dropdownMenuNode.style.maxHeight = `${height - menuConstraints.top - elementsSpacing}px`

      // The Overlay component blocks all touchevents on mobile devices but this extra class allows scrolling of these elements
      dropdownMenuNode.classList.add('body-scroll-lock-ignore')
    }
  }, 100)
}
