import { Controller } from 'stimulus'

export default class OurCompanyController extends Controller {
  static targets = ['statisticAmounts']

  static viewportObserver

  connect() {
    OurCompanyController.viewportObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const animation = OurCompanyController.getEasingFunction(entry.target.dataset.easing.trim())

          if (!entry.target.dataset.isCountingValue && entry.isIntersecting && animation) {
            OurCompanyController.animateValue(
              entry.target,
              parseInt(entry.target.dataset.start),
              parseInt(entry.target.dataset.end),
              parseInt(entry.target.dataset.duration),
              animation
            )
            entry.target.dataset.isCountingValue = entry.isIntersecting
          }
        })
      },
      {
        threshold: this.thresholdValue,
      }
    )
    this.statisticAmountsTargets.forEach((target) => OurCompanyController.viewportObserver.observe(target))
  }

  static getEasingFunction(easingType) {
    switch (easingType) {
      case 'quadratic':
        return OurCompanyController.quadratic
      case 'linear':
        return OurCompanyController.linear
      case 'constant':
        return OurCompanyController.constant
      default:
        return null
    }
  }

  static constant(duration, range) {
    return duration / range
  }

  static linear(duration, range, current) {
    return ((duration * 2) / range ** 2) * current
  }

  static quadratic(duration, range, current) {
    return ((duration * 3) / range ** 3) * current ** 2
  }

  static animateValue(element, start, end, duration, easing) {
    var range = end - start
    var current = start
    element.textContent = start
    var increment = end > start ? 1 : -1

    var step = function () {
      current += increment
      element.innerHTML = current

      if (current != end) {
        setTimeout(step, easing(duration, range, current))
      }
    }

    setTimeout(step, easing(duration, range, start))
  }
}
