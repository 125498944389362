import { Controller } from 'stimulus'
import { useDispatch } from 'stimulus-use'
import h from 'hyperscript'
import upperFirst from 'lodash/upperFirst'

import License from '../classes/license'
import LicenseLinkController from './license-link_controller'
import SnackBar from '../../_components/snackbar/snackbar'
import reveal from '../components/reveal'
import { pluralize } from '../base/utils'

export default class LicenseCheckerController extends Controller {
  static TYPE_DELAY = 750

  _licenseInstance = undefined

  errorSnackbar = null

  isLicenseInUrl = false

  static strings = {
    ERROR_GENERIC: 'Something went wrong while fetching your license’s data.',
    ERROR_INVALID_KEY: 'The license key you entered doesn’t seem to be valid.',
    ERROR_VOID: 'This license key is no longer valid. It may have been redeemed for credit against a subscription.',
    PREPOSITION_ACTIVE: 'until',
    PREPOSITION_EXPIRING: 'on',
    PREPOSITION_EXPIRED: 'on',
  }

  static targets = [
    'input', // License input field
    'help', // Help info and links (disappear with valid license)
    'overviewPill', // Overview pills
    // Text Labels
    'stateLabel',
    'datePrepositionLabel',
    'expiryDateLabel',
    'unusedSeatsLabel',
    'totalSeatsLabel',
    'seatsPluralLabel',
    'timeLeftLabel',
  ]

  static classes = ['error', 'loading', 'active', 'expiring', 'expired', 'entered']

  connect() {
    useDispatch(this)
    this.makeHelpTransitionFaster()
    this.checkUrlForLicense()
  }

  //
  // License setting, getting, and loading
  //

  /**
   * Setter for license
   * @param {?License} newLicense - the new license, or a falsy value to reset the checker
   * @memberof LicenseCheckerController
   */
  set license(newLicense) {
    this._licenseInstance = newLicense
    if (this.license) {
      this.load()
    } else {
      this.clean()
    }
  }

  get license() {
    return this._licenseInstance
  }

  checkUrlForLicense() {
    const licenseFromUrl = License.newFromURL()
    if (licenseFromUrl) {
      this.license = licenseFromUrl
      this.isLicenseInUrl = true
    }
  }

  load() {
    this.element.classList.remove(this.errorClass)
    this.element.classList.add(this.loadingClass)

    this.license
      .fetchData()
      .then(async () => {
        this.element.classList.remove(this.loadingClass)

        if (this.license.isVoid) {
          this.element.classList.add(this.errorClass)
          this.displayVoidError()
          return
        }
        if (this.license.hasRedemptionsBlocked) {
          this.displayRedemptionsBlockWarning()
        }

        await this.license.fetchLicensePricesAndDates()
        this.dispatch('success', this.license)
        LicenseLinkController.updateAll(this.license)
        this.render()
        this.element.classList.add(this.enteredClass)

        if (this.isLicenseInUrl) {
          this.displayObfuscatedKey()
        }
      })
      .catch((error) => {
        this.element.classList.add(this.errorClass)
        this.element.classList.remove(this.loadingClass)
        this.displayGenericError(error)
      })
  }

  /**
   * Reset the license to blank state
   */
  clean() {
    this.dispatch('clean')

    this.isLicenseInUrl = false
    LicenseLinkController.updateAll(this.license)
    if (this.errorSnackbar) this.errorSnackbar.hide()
    this.element.classList.remove(
      this.errorClass,
      this.enteredClass,
      this.activeClass,
      this.expiringClass,
      this.expiredClass
    )
  }

  //
  // Actions
  //

  /**
   * Immediately parse and check the license key input
   * @param {?Event} event
   */
  parseInput(event) {
    if (event && event.type === 'submit') event.preventDefault()

    const key = this.inputTarget.value.trim().toUpperCase()

    if (!key || key === '') return

    if (!License.isKeyFormatValid(key)) {
      this.displayInvalidKeyError()
      return
    }

    this.license = new License({ key })
  }

  /**
   * Called whenever license key input changes value
   * @param {Event} _event
   */
  inputChanged(_event) {
    this.license = null
    if (License.isKeyFormatValid(this.inputTarget.value.trim())) {
      this.parseInput()
    }
  }

  // ###############################################################################################
  // Rendering methods
  // These are functions responsible for interacting with the DOM and rendering stuff
  // ###############################################################################################

  makeHelpTransitionFaster() {
    if (!this.hasHelpTarget) return

    this.helpTarget.addEventListener(
      'transitionend',
      () => {
        this.helpTarget.classList.add('reveal--duration-card')
        this.helpTarget.setAttribute('data-reveal-delay', 100)
      },
      { once: true }
    )
  }

  displayVoidError() {
    const snackbarText = h(
      'p',
      `${LicenseCheckerController.strings.ERROR_VOID} `,
      h('a', { href: '/support/contact/switching', title: 'Contact us' }, 'Need help'),
      '?'
    )

    this.errorSnackbar = new SnackBar(snackbarText, { modifiers: 'error no-max-width', position: 'top' })
  }

  displayRedemptionsBlockWarning() {
    const snackbarText = h(
      'p',
      `${License.strings.WARNING_REDEMPTIONS_BLOCKED} `,
      h('a', { href: '/support/contact/switching', title: 'Contact us' }, 'Need help'),
      '?'
    )

    this.errorSnackbar = new SnackBar(snackbarText, { modifiers: 'warning no-max-width', position: 'top' })
  }

  displayInvalidKeyError() {
    const snackbarText = h(
      'p',
      `${LicenseCheckerController.strings.ERROR_INVALID_KEY} Perhaps we can `,
      h('a', { href: '/support/contact/admin', title: 'Contact us' }, 'help'),
      '?'
    )

    this.errorSnackbar = new SnackBar(snackbarText, { modifiers: 'error no-max-width', position: 'top' })
  }

  displayGenericError(error) {
    const snackbarText = h(
      'p',
      `${LicenseCheckerController.strings.ERROR_GENERIC} Please try again or `,
      h('a', { href: '/support/contact/switching', title: 'Contact us' }, 'contact us'),
      '.'
    )

    this.errorSnackbar = new SnackBar(snackbarText, { modifiers: 'error no-max-width', position: 'top' })

    throw new Error(error)
  }

  render() {
    this.element.classList.add(this.stateClass)
    this.setLabels()
    this.revealOverview()
  }

  setLabels() {
    // State/date
    this.setTargetText('stateLabel', this.license.stateString)
    this.setTargetText('expiryDateLabel', this.license.expirationDateFormatted)
    this.setTargetText('datePrepositionLabel', this.datePreposition)

    // Seats
    this.setTargetText('unusedSeatsLabel', this.license.unusedSeats)
    this.setTargetText('totalSeatsLabel', this.license.totalSeats)
    this.setTargetText('seatsPluralLabel', pluralize(this.license.totalSeats, 'seat', 'seats'))

    // Time left
    this.setTargetText(
      'timeLeftLabel',
      this.license.hasWorkspaceCredit
        ? `${this.license.monthsUntilExpiry} ${pluralize(this.license.monthsUntilExpiry, 'month', 'months')}`
        : 'No time'
    )
  }

  setTargetText(targetName, value, errorsWithoutMatch = false) {
    if (errorsWithoutMatch || this[`has${upperFirst(targetName)}Target`]) {
      this[`${targetName}Target`].textContent = value
    }
  }

  revealOverview() {
    const showPills = () => {
      this.overviewPillTargets.forEach((pill) => reveal(pill))
    }

    if (this.hasHelpTarget) {
      this.helpTarget.addEventListener('transitionend', showPills, { once: true })
      this.helpTarget.classList.remove('show')
    } else {
      showPills()
    }
  }

  get datePreposition() {
    switch (this.license.stateId) {
      case License.stateIds.ACTIVE:
        return LicenseCheckerController.strings.PREPOSITION_ACTIVE
      case License.stateIds.EXPIRING:
        return LicenseCheckerController.strings.PREPOSITION_EXPIRING
      case License.stateIds.EXPIRED:
        return LicenseCheckerController.strings.PREPOSITION_EXPIRED
      default:
        return ''
    }
  }

  get stateClass() {
    switch (this.license.stateId) {
      case License.stateIds.ACTIVE:
        return this.activeClass
      case License.stateIds.EXPIRING:
        return this.expiringClass
      case License.stateIds.EXPIRED:
        return this.expiredClass
      default:
        return ''
    }
  }

  displayObfuscatedKey() {
    this.inputTarget.value = this.license.obfuscatedKey
  }
}
