import { Controller } from 'stimulus'
import { addYears } from 'date-fns'

import License from '../classes/license'
import SnackBar from '../../_components/snackbar/snackbar'
import { niceDateFromTimestamp } from '../base/utils'

export default class LicenseOperationController extends Controller {
  static MIN_SEATS = 1

  static targets = [
    'renewUpdatesUntil', // Renew: updates until date
    'renewTotalCost', // Renew: total cost
    'addSeatsMonthsLeft', // Add seats: months left on license
    'addSeatsTotalCost', // Add seats: total cost
    'submit', // CTA button
    'smallCopy', // Small copy paragraph below CTA
    'paymentProduct', // FastSpring Payment product that's coming from the API
    'paymentTotal', // FastSpring Payment total with a specific format
    'paymentRef', // FastSpring Payment ref coming from API
    'renewalDate', // Used in the inactive state
    'licenseStatus', // Card overview license status
    'seatsUsed', // Card overview seats used
    'expiryDate', // Card overview expiry date
    'card', // Card wrapper element
    'disabledCard', // Card placeholder for when an operation isn't available
    'enabledCard', // Card for when an operation is available
    'alertFewerSeats', // How many seats below the currently allowed
    'input', // Input field for seats amount
  ]

  static classes = ['fewerSeats', 'disabled']

  static values = {
    type: String,
  }

  licenseInst = null

  errorSnackbar = null

  connect() {
    this.disable()
  }

  onInputChange(event) {
    const value = parseInt(event.target.value, 10) || 1

    this.updateCardValues(value)
    this.toggleFewerSeatsAlert(value)
    this.hideError()
  }

  onIncrease(_event) {
    const value = parseInt(this.inputTarget.value, 10)
    const newValue = value < LicenseOperationController.MIN_SEATS ? LicenseOperationController.MIN_SEATS : value + 1

    this.updateCardValues(newValue)
    this.toggleFewerSeatsAlert(newValue)
    this.hideError()
  }

  onDecrease(_event) {
    const value = parseInt(this.inputTarget.value, 10)
    const newValue = value > LicenseOperationController.MIN_SEATS ? value - 1 : LicenseOperationController.MIN_SEATS

    this.updateCardValues(newValue)
    this.toggleFewerSeatsAlert(newValue)
    this.hideError()
  }

  toggleFewerSeatsAlert(currentSeats) {
    if (this.typeValue === 'addSeats') {
      return
    }

    const isLessThanCurrent = this.licenseInst.maxSeats > currentSeats
    this.alertFewerSeatsTarget.textContent = this.licenseInst.maxSeats - currentSeats

    this.element.classList.toggle(this.fewerSeatsClass, isLessThanCurrent)
  }

  async onSubmit(event) {
    // Prevent submit event from firing right away
    event.preventDefault()

    const newSeats = this.inputTarget.value

    if (!newSeats || parseInt(newSeats, 10) < LicenseOperationController.MIN_SEATS) {
      this.displayZeroSeatsError()
      return
    }

    const paymentData = await this.licenseInst.fetchPaymentInfo(newSeats, this.element.dataset.apiEndpoint, false)
    this.injectPaymentInfoInForm(paymentData)

    // Now that we injected the payment data in the form we can submit it
    event.target.submit()
  }

  injectPaymentInfoInForm(paymentData) {
    const total = paymentData.data.total
    const totalInteger = Math.floor(total)
    const totalFraction = Math.floor((total * 100) % 100)

    this.paymentProductTarget.value = paymentData.data.payment_info.product
    this.paymentTotalTarget.value = `total_integer=${totalInteger},total_fraction=${totalFraction}`
    this.paymentRefTarget.value = paymentData.data.payment_info.ref
  }

  updateCardValues(seatsValue) {
    if (!this.typeValue || !this.licenseInst) {
      return
    }

    if (this.typeValue === 'renew') {
      this.renewUpdatesUntilTarget.textContent = niceDateFromTimestamp(this.licenseInst.renewalNewExpiryDate)
      this.inputTarget.value = seatsValue
      this.renewTotalCostTarget.textContent = License.annualSeatsCost(seatsValue, this.licenseInst.renewalCostPerYear)

      this.renewalDateTarget.textContent = this.licenseInst.renewalDateFormatted
    } else if (this.typeValue === 'addSeats') {
      this.addSeatsMonthsLeftTarget.textContent = this.licenseInst.monthsUntilExpiry
      this.inputTarget.value = seatsValue
      this.addSeatsTotalCostTarget.textContent = License.seatsCostForTimeLeft(seatsValue, this.licenseInst.newSeatCost)
    }
  }

  disable() {
    this.disabledCardTarget.classList.remove(this.disabledClass)
    this.enabledCardTarget.classList.add(this.disabledClass)
  }

  enable() {
    this.disabledCardTarget.classList.add(this.disabledClass)
    this.enabledCardTarget.classList.remove(this.disabledClass)
  }

  clean() {
    this.disable()
    this.updateCardValues(LicenseOperationController.MIN_SEATS)
  }

  displayZeroSeatsError() {
    this.hideError()

    this.errorSnackbar = new SnackBar('Can’t proceed with 0 seats — please increase.', {
      modifiers: 'error no-max-width',
      position: 'top',
    })
  }

  hideError() {
    if (this.errorSnackbar) {
      this.errorSnackbar.remove()
      this.errorSnackbar = null
    }
  }

  updateCardOverviewValues() {
    const statusString = this.licenseInst.isExpiring ? 'Expiring' : this.licenseInst.stateString
    this.licenseStatusTarget.textContent = statusString
    this.seatsUsedTarget.textContent = `${this.licenseInst.usedSeats} of ${this.licenseInst.maxSeats}`
    this.expiryDateTarget.textContent = this.licenseInst.expirationDateFormatted
  }

  set license(license) {
    if (!license) {
      this.clean()
      return
    }

    this.licenseInst = license

    const seatsValue = this.typeValue === 'renew' ? this.licenseInst.maxSeats : LicenseOperationController.MIN_SEATS
    this.updateCardValues(seatsValue)
    this.updateCardOverviewValues()

    if (
      this.typeValue === 'renew' &&
      (this.licenseInst.stateId === License.stateIds.EXPIRING || this.licenseInst.stateId === License.stateIds.EXPIRED)
    ) {
      this.enable()
    } else if (
      this.typeValue === 'addSeats' &&
      (this.licenseInst.stateId === License.stateIds.EXPIRING || this.licenseInst.stateId === License.stateIds.ACTIVE)
    ) {
      this.enable()
    }
  }
}
