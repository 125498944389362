import upperFirst from 'lodash/upperFirst'
import FormController from '../../../_components/form/form_controller'
import appVersionData from '../../../_js/utils/app-version-data'
import * as userUtils from '../../../_js/helpers/user'

const HS_ENDPOINT_PREFIX = 'https://api.hsforms.com/submissions/v3/integration/submit'
const HS_PORTAL_ID = 7692458
const HS_SUBSCRIPTION_ID = 9320643
const HS_SANDBOX_PORTAL_ID = 20854638
const HS_SANDBOX_FORM_GUID = 'bc45c13f-c55e-4910-82a0-0c8d415262e8'
const HS_SANDBOX_SUBSCRIPTION_ID = 24734259

const SUBMISSION_DELAY = 20000

export default class FormTopic extends FormController {
  static targets = ['topic', 'topicSelector', 'subject', 'summary', 'email']

  connect() {
    this.isProd = ['production', 'staging'].indexOf(this.element.dataset.env) !== -1
    this.setFormFieldsFromURL()
    this.prepopulateFields()

    this.resetTimestamp()
  }

  resetTimestamp() {
    this.startTime = Date.now()
  }

  prepopulateFields(event) {
    if (event && event.type === 'storage' && event.key !== null && event.key !== userUtils.LOCALSTORAGE_USER_KEY) return

    this.user = userUtils.isLoggedIn() ? userUtils.currentUser() : null

    if (this.user) {
      this.emailTarget.value = this.user.email
    }
  }

  selectTopic(event) {
    const optionNode = event.target.querySelector(`option[value='${event.target.value}']`)
    this.element.dataset.hubspotFormGuid = optionNode.dataset.hubspotFormGuid
    this.subjectTarget.placeholder = optionNode.dataset.subjectPlaceholder
    this.summaryTarget.placeholder = optionNode.dataset.descriptionPlaceholder

    // Resize and set textarea content
    this.summaryTarget.style.height = null
    this.summaryTarget.value = optionNode.dataset.descriptionValue
    this.summaryTarget.style.height = `${this.summaryTarget.scrollHeight}px`

    this.topicTargets.forEach((topicNode) => {
      topicNode.classList.toggle('is-hidden', topicNode.id !== `form-aside-${event.target.value}`)
    })
  }

  // Replace original function to include Sketch system info in the FormData
  submitRemotely() {
    // Generate action URL on submit
    this.generateFormActionURL()

    // Check if the first submission happens after a certain delay (to prevent bot submissions that happens too fast)
    if (Date.now() - this.startTime < SUBMISSION_DELAY) return

    // Capture form data before any inputs are possibly disabled
    const formData = new FormData(this.element)

    // Get Sketch related system info from local storage
    const systemInfo = appVersionData.getReadableFromStorage()

    const postData = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formData.get('email'),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'subject',
          value: formData.get('headline'),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'content',
          value: formData.get('text'),
        },
        {
          objectTypeId: this.topicSelectorTarget.value === !this.isProd ? '0-1' : '0-5',
          name: 'system_info',
          value: systemInfo || '',
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: formData.has('gdpr'),
          text: "I confirm that I have read and agree to Sketch's Terms of Service and Privacy Statement",
          communications: [
            {
              value: formData.has('newsletter'),
              subscriptionTypeId: this.isProd ? HS_SUBSCRIPTION_ID : HS_SANDBOX_SUBSCRIPTION_ID,
              text: 'Consent to keep me updated on news and offers',
            },
          ],
        },
      },
    }

    this.disableSubmission()

    fetch(this.element.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cors: true,
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Submission failed')
        else {
          this.resetTimestamp()
          return this.remoteSubmissionSuccessful()
        }
      })
      .catch(() => this.remoteSubmissionFailed())
      .finally(() => this.enableSubmission())
  }

  generateFormActionURL() {
    this.element.setAttribute(
      'action',
      `${HS_ENDPOINT_PREFIX}/${this.isProd ? HS_PORTAL_ID : HS_SANDBOX_PORTAL_ID}/${
        this.isProd ? this.element.dataset.hubspotFormGuid : HS_SANDBOX_FORM_GUID
      }`
    )
  }

  setFormFieldsFromURL() {
    const urlParams = new URLSearchParams(window.location.search)

    // Set topic dropdown from URL
    const topicValue = urlParams.get('topic') || null
    if (topicValue) {
      this.topicSelectorTarget.value = topicValue
      this.topicSelectorTarget.dispatchEvent(new Event('change'))
    }

    // Iterate through text fields to set them from URL
    // ⚠️ The fields listed below will override whatever values the 'topic' from above may have
    // previously set.
    const textFieldTargetNames = ['email', 'subject', 'summary']
    textFieldTargetNames.forEach((textFieldTargetName) => {
      const paramValue = urlParams.get(textFieldTargetName) || null
      if (paramValue && this[`has${upperFirst(textFieldTargetName)}Target`]) {
        this[`${textFieldTargetName}Target`].value = paramValue
      }
    })
  }
}
