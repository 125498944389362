import { Controller } from 'stimulus'
import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper'
import simplexNoise from 'simplex-noise'
import { BREAKPOINTS } from '../../_js/base/consts'
import mediaFrom from '../../_js/utils/media-from'

const AUTOPLAY_DELAY = 4200
const BULLET_ACTIVE_CLASS = 'feature__title--active'

function createScenariosSwiper(element) {
  return new Swiper(element, {
    slidesPerView: 1,
    spaceBetween: 32,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loop: true,
    grabCursor: true,
    modules: [Autoplay, Pagination],
    pagination: {
      el: '.scenarios__pagination',
      clickable: true,
    },
    breakpoints: {
      1440: {
        autoplay: {
          delay: 20000,
        },
        spaceBetween: 64,
      },
    },
  })
}

const featuresSwiperOptions = {
  effect: 'fade',
  allowTouchMove: false,
  fadeEffect: { crossFade: true },
  direction: 'vertical',
  slidesPerView: 'auto',
  loop: false,
  autoplay: {
    delay: AUTOPLAY_DELAY,
    disableOnInteraction: false,
  },
  modules: [Autoplay, EffectFade, Pagination],
  breakpoints: {
    1024: {
      pagination: {
        el: '.features__list',
        clickable: true,
        renderBullet: function (index, className) {
          const parent = this.el.closest('.app__features')
          const titles = Array.from(parent.querySelectorAll('.feature')).map((el) => {
            const badge = el.getAttribute('data-badge')
            if (badge) {
              return `<span class="feature__title__badge">${badge}</span> ${el.getAttribute('data-title')}`
            }
            return el.getAttribute('data-title')
          })

          const circleColor = parent.dataset.circleColor ?? '#FF7843'
          const circleOpacityColor = parent.dataset.circleOpacityColor ?? parent.dataset.circleColor ?? '#F26725'
          const circleOpacity = parent.dataset.circleOpacity ?? 0.3

          return `
            <div class="${className}">
              <div class="feature__title__circle">
                <svg width="24" height="24">
                  <circle cx="12" cy="12" r="10" stroke="${circleOpacityColor}" stroke-width="4" fill="none" stroke-opacity="${circleOpacity}" />
                  <circle class="feature__title__circle__progress" cx="12" cy="12" r="10" stroke="${circleColor}" stroke-width="4" fill="none" stroke-opacity="1" stroke-dasharray="75" stroke-dashoffset="0" />
                </svg>
              </div>
              <span class="feature__title__label">${titles[index]}</span>
            </div>
          `
        },
        bulletClass: 'feature__title',
        bulletActiveClass: BULLET_ACTIVE_CLASS,
      },
    },
  },
}

// Swiper restarts autoplay when user changes tab. We restart the CSS animation here.
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    const activeElements = document.querySelectorAll(`.${BULLET_ACTIVE_CLASS}`)
    activeElements.forEach((activeElement) => {
      activeElement.classList.remove(BULLET_ACTIVE_CLASS)
      setTimeout(() => {
        activeElement.classList.add(BULLET_ACTIVE_CLASS)
      }, 1)
    })
  }
})

export default class HomeController extends Controller {
  static targets = [
    'anchorLeft',
    'anchorRight',
    'cursor',
    'feature',
    'featuresWrapper',
    'tabs',
    'heroNumber',
    'home',
  ]

  swiperFeatures

  eventMouseMove = this.handleMouseMove.bind(this)

  eventMouseUp = this.handleMouseUp.bind(this)

  connect() {
    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.pageLoaded = false
      window.addEventListener('load', () => {
        this.pageLoaded = true
        this.drawLines()
      })
    }

    // Generate noise for cursor movements
    // eslint-disable-next-line new-cap
    this.noise = new simplexNoise()
    requestAnimationFrame(this.render.bind(this), 1000 / 500)

    this.swiperMac = createScenariosSwiper('.app__scenarios--mac-app .scenarios__slider')
    this.swiperWeb = createScenariosSwiper('.app__scenarios--web-app .scenarios__slider')
    this.handleSlideChange()

    window.addEventListener('resize', () => {
      if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
        if (!this.swiperFeatures) {
          this.featuresWrapperTargets.forEach((target) => target.classList.add('swiper-wrapper'))
          this.swiperFeatures = new Swiper('.app__features', featuresSwiperOptions)
        }
      } else {
        if (this.swiperFeatures) {
          this.swiperFeatures.forEach((instance) => instance.destroy())
          this.swiperFeatures = null
        }
        this.featuresWrapperTargets.forEach((target) => target.classList.remove('swiper-wrapper'))
      }
    })
  }

  handleMouseDown(event) {
    if (event.button == 0 && mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      this.isMoving = true
      document.addEventListener('mousemove', this.eventMouseMove)
      document.addEventListener('mouseup', this.eventMouseUp)
    }
  }

  handleMouseMove(event) {
    if (this.isMoving) {
      event.preventDefault()
      this.repositionLines()
    }
  }

  handleMouseUp() {
    document.removeEventListener('mousemove', this.eventMouseMove)
    document.removeEventListener('mouseup', this.eventMouseUp)
  }

  handleTabClick(event) {
    const selectedTab = event.target.dataset.slug
    const selectedApp = event.target.parentNode.parentNode.dataset.app
    let currentSwiper
    if (selectedApp === 'mac-app') {
      currentSwiper = this.swiperMac
    } else if (selectedApp === 'web-app') {
      currentSwiper = this.swiperWeb
    }
    const slide = document.querySelector(`.swiper-slide[data-slug='${selectedTab}']`)
    const slideIndex = [].indexOf.call(slide.parentNode.children, slide)
    currentSwiper.slideTo(slideIndex)
  }

  handleSlideChange = () => {
    const handleSlideChangeForSwiper = (swiper) => {
      swiper.on('slideChange', () => {
        const currentSlideIndex = swiper.activeIndex
        const currentSlide = swiper.slides[currentSlideIndex]
        const currentTabSlug = currentSlide.dataset.slug
        const relatedTab = document.querySelector(`[data-slug='${currentTabSlug}']`)
        relatedTab.classList.add('tabs__button--active')

        const otherTabs = Array.from(relatedTab.parentNode.parentNode.children)
          .filter((tabWrapper) => tabWrapper !== relatedTab.parentNode)
          .map((tabWrapper) => tabWrapper.querySelector('.tabs__button'))
          .filter((tab) => tab !== relatedTab)

        otherTabs.forEach((tab) => tab.classList.remove('tabs__button--active'))
      })
    }

    handleSlideChangeForSwiper(this.swiperMac)
    handleSlideChangeForSwiper(this.swiperWeb)
  }

  drawLines() {
    const elements = {
      pitch: document.querySelector('.pitch'),
      pitchButton: document.querySelector('.pitch__button.button--primary'),
      macSticker: document.querySelector('.app__sticker--mac-app'),
    }

    const commonProperties = {
      size: 1,
      color: '#03cbbc',
      startPlug: 'disc',
      startPlugSize: 3,
      startPlugColor: '#fff',
      startPlugOutline: true,
      startPlugOutlineColor: '#03cbbc',
      endPlug: 'arrow3',
      endPlugSize: 3,
      endPlugColor: '#03cbbc',
    }

    const specialProperties = {
      ...commonProperties,
      endPlug: 'disc',
      endPlugColor: '#fff',
      endPlugOutline: true,
      endPlugOutlineColor: '#03cbbc',
      startPlug: 'arrow3',
      startPlugColor: '#03cbbc',
    }

    this.lines = []
    const connections = [
      [elements.pitch, this.anchorRightTarget, commonProperties],
      [this.anchorLeftTarget, elements.pitch, specialProperties],
      [elements.pitchButton, elements.macSticker, commonProperties],
    ]

    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      connections.forEach(([start, end, properties]) => {
        this.lines.push(new LeaderLine(start, end, properties))
      })
    }
  }

  repositionLines() {
    this.lines?.forEach((line) => {
      line.position()
    })
  }

  // Generate cursor movements
  render(frame) {
    const speeds = [0.0001, -0.0001, 0.00015, 0.00015, -0.0001, 0.0001]
    for (let i = 0; i < this.cursorTargets.length; i++) {
      const parent = this.cursorTargets[i].parentElement
      if (parent.getAttribute('data-entrance-is-intersecting-value') === 'true') {
        const offset = Math.random() - 0.5
        const noiseX = (this.noise.noise2D(0, (frame + offset) * speeds[i]) + 1) / 2
        const noiseY = (this.noise.noise2D(1, (frame + offset) * speeds[i]) + 1) / 2
        const rect = parent.getBoundingClientRect()
        const x = noiseX * rect.width
        const y = noiseY * rect.height
        this.cursorTargets[i].style.setProperty('--translate-x', x + 'px')
        this.cursorTargets[i].style.setProperty('--translate-y', y + 'px')
      }
    }

    requestAnimationFrame(this.render.bind(this))
  }
}
