/* eslint-disable max-classes-per-file */
import { Controller } from 'stimulus'
import Pickr from '@simonwep/pickr'
import { hsla, parseToRgba } from 'color2k'
import { BREAKPOINTS } from '../../_js/base/consts'
import mediaFrom from '../../_js/utils/media-from'

export default class ColorVariablesController extends Controller {
  static targets = ['colorName']

  pickr = null

  primaryColor = null

  scribbleColor = null

  gradientColor1 = null

  gradientColor2 = null

  gradientColor3 = null

  connect() {
    if (mediaFrom(BREAKPOINTS.VIEWPORT_L)) {
      const styles = getComputedStyle(document.documentElement)
      this.primaryColor = styles.getPropertyValue('--primary-color').trim()
      this.scribbleColor = styles.getPropertyValue('--scribble-color').trim()
      this.gradientColor1 = styles.getPropertyValue('--gradient-color-1').trim()
      this.gradientColor2 = styles.getPropertyValue('--gradient-color-2').trim()
      this.gradientColor3 = styles.getPropertyValue('--gradient-color-3').trim()

      this.pickr = Pickr.create({
        el: '#color-picker',
        theme: 'nano',
        default: this.primaryColor,
        comparison: false,
        lockOpacity: true,
        appClass: 'hero__color-picker__picker',
        components: {
          preview: true,
          opacity: false,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsva: false,
            input: false,
            clear: false,
            save: false,
          },
        },
      })

      this.pickr.on('change', (color) => {
        this.handleColorShift(color.toHSLA())
      })
    }
  }

  handleColorShift(color) {
    const [hue] = color
    let [, saturation, lightness] = color
    saturation /= 100
    lightness /= 100

    this.primaryColor = hsla(hue, saturation, lightness, 1)

    this.gradientColor1 = hsla(hue - 22, saturation - 0.03, lightness - 0.04, 1)
    this.gradientColor2 = hsla(hue - 17, saturation - 0.03, lightness - 0.22, 1)
    this.gradientColor3 = hsla(hue - 9, saturation - 0.15, lightness - 0.31, 1)
    this.scribbleColor = hsla(hue, saturation - 0.11, lightness - 0.14, 1)

    this.setCssVariables()
    this.setPickerText()
  }

  setPickerText() {
    const [r, g, b] = parseToRgba(this.primaryColor)
    this.colorNameTarget.textContent = `RGB(${parseInt(r)},${parseInt(g)},${parseInt(b)})`
  }

  setCssVariables() {
    document.documentElement.style.setProperty('--primary-color', this.primaryColor)
    document.documentElement.style.setProperty('--scribble-color', this.scribbleColor)
    document.documentElement.style.setProperty('--gradient-color-1', this.gradientColor1)
    document.documentElement.style.setProperty('--gradient-color-2', this.gradientColor2)
    document.documentElement.style.setProperty('--gradient-color-3', this.gradientColor3)
  }
}
